import { t } from '@lingui/core/macro';

import OfflineActivityTableContainer from '@/app/vendors/OfflineActivityTableContainer';
import VendorActivityTableContainer from '@/app/vendors/VendorActivityTableContainer';
import { Flex } from '@/components/Flex';
import InstantSearch, { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';

import VendorActivityKPIs from './VendorActivityKPIs';
import VendorFitScoringTable from './VendorFitScoringTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 100,
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
};

const IGNORED_FIELDS = ['filters', 'page', 'size'] as (keyof QueryState)[];

const OFFLINE_EVENTS_DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
};

const OFFLINE_EVENTS_IGNORED_FIELDS = ['filters', 'page', 'size'] as (keyof QueryState)[];

const VendorOverview = () => {
  const hasOfflineEvents = useFeatureFlag(FEATURE_FLAG.offlineEvents);

  return (
    <Page title={t`Vendors`} pageName={t`All Vendors`}>
      <InstantSearch
        prefix="v"
        defaultQueryState={DEFAULT_QUERY_STATE}
        ignoredFields={IGNORED_FIELDS}
      >
        <PageFilterProvider>
          <Flex vertical gap="large" fullWidth>
            <Flex justify="space-between">
              <PageDateRangeFilter />
              <PageAudienceFilter />
            </Flex>
            <VendorActivityKPIs />
            <VendorActivityTableContainer />
            {hasOfflineEvents && (
              <InstantSearch
                prefix="o"
                defaultQueryState={OFFLINE_EVENTS_DEFAULT_QUERY_STATE}
                ignoredFields={OFFLINE_EVENTS_IGNORED_FIELDS}
              >
                <OfflineActivityTableContainer />
              </InstantSearch>
            )}
            <VendorFitScoringTable />
          </Flex>
        </PageFilterProvider>
      </InstantSearch>
    </Page>
  );
};

export default VendorOverview;
