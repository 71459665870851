import classNames from 'classnames';
import { ReactNode, useRef } from 'react';

import Warning from '@/assets/svg/warning.svg?react';
import { Flex } from '@/components/Flex';
import InfoPopover from '@/components/InfoPopover';
import Spin from '@/components/Spin';
import Tooltip from '@/components/Tooltip';
import { Link, Text } from '@/components/typography';

import DeltaLabel from './DeltaLabel';
import styles from './KpiMetric.module.scss';
import KpiMetricLoader from './KpiMetricLoader';

type Props = {
  className?: string;
  color?: string;
  label: ReactNode;
  infoPopover?: {
    title?: ReactNode; // use this if you want a different title for this than `title`
    body: ReactNode;
  };
  value?: number | string | null;
  valueLink?: string | false | 0;
  percentChange?: number | null | false;
  caption?: ReactNode;
  captionLink?: string | false;
  isLoading?: boolean;
  error: Error | undefined | null;
};

const KpiMetric = ({
  className = '',
  color = undefined,
  label,
  infoPopover,
  value,
  valueLink,
  percentChange,
  caption,
  captionLink,
  isLoading,
  error,
}: Props) => {
  const stickyValue = useRef<number | string | null>(null);

  if (value != null) {
    stickyValue.current = value;
  }

  const showSkeletonLoader = isLoading && stickyValue.current == null;
  const showSpinnerLoader = isLoading && stickyValue.current != null;

  return (
    <Spin wrapperClassName={styles.spinner} spinning={showSpinnerLoader}>
      <div
        className={classNames(styles.metric, className)}
        style={{ backgroundColor: color, borderColor: color }}
      >
        <Text className={styles.title} variant="footnote" color="dark-grey" weight="regular">
          <Flex gap="xsmall" align="center">
            {label}
            {infoPopover && (
              <InfoPopover title={infoPopover.title ?? label} body={infoPopover.body} />
            )}
          </Flex>
        </Text>
        {error ? (
          <Warning className={styles.warning} />
        ) : showSkeletonLoader ? (
          <KpiMetricLoader />
        ) : (
          <>
            <div className={styles.valueContainer}>
              <Tooltip body={stickyValue.current} mouseEnterDelay="slow">
                {valueLink ? (
                  <Link to={valueLink} className={styles.value} variant="kpi" weight="medium">
                    {stickyValue.current}
                  </Link>
                ) : (
                  <Text className={styles.value} variant="kpi" weight="medium">
                    {stickyValue.current}
                  </Text>
                )}
              </Tooltip>
              {percentChange !== false && percentChange !== undefined && (
                <DeltaLabel className={styles.deltaLabel} percentChange={percentChange} />
              )}
            </div>
            {
              <Text className={styles.caption} variant="caption1" weight="light">
                {captionLink ? (
                  <Link to={captionLink} variant="caption1" color="static-black">
                    {caption ? caption : '\u00A0'}
                  </Link>
                ) : caption ? (
                  caption
                ) : (
                  '\u00A0'
                )}
              </Text>
            }
          </>
        )}
      </div>
    </Spin>
  );
};

export default KpiMetric;
