import { Trans } from '@lingui/react/macro';
import { MouseEvent, useMemo } from 'react';

import { CompanyDetailType } from '@/api/activity';
import CompanyLogo from '@/app/web-traffic-activity/CompanyLogo';
import { Flex } from '@/components/Flex';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType } from '@/components/Table';
import { ActionIcon } from '@/components/buttons';
import { Link } from '@/components/typography';
import SolAPIError from '@/error/SolAPIError';
import { useTableState } from '@/providers/TableState';
import { ROUTES } from '@/router';

import { type ALL_WEB_TRAFFIC_FIELDS, getWebTrafficFieldName } from './webTrafficFields';

type Props = {
  data?: CompanyDetailType[];
  totalResults?: number;
  isLoading?: boolean;
  error: SolAPIError | null;
  onSelectedRowChange?: (row: CompanyDetailType | null) => void;
};

const WebTrafficActivityTable = ({
  data,
  totalResults,
  isLoading,
  error,
  onSelectedRowChange,
}: Props) => {
  const { columns } = useTableState<CompanyDetailType>();
  const sortFields = ROUTES.webTrafficActivity.searchParams['w.sortBy'].values;

  const allColumns = useMemo<ColumnsType<CompanyDetailType>>(() => {
    return [
      {
        title: getWebTrafficFieldName('w.name'),
        key: 'name',
        fixed: 'left',
        sorter: sortFields.indexOf('name') >= 0,
        width: 150,
        render: (text, field) => (
          <Flex gap="small">
            <CompanyLogo size="small" name={field.name} domain={field.tld} />
            <Link variant="caption1">{field.name || field.tld}</Link>
          </Flex>
        ),
      },
      ...(columns ?? []),
      {
        title: '',
        key: 'actions',
        align: 'right',
        width: 40,
        render: (text, field) => (
          <ActionIcon
            color="black"
            icon={<img src="/assets/images/linkedin-icon.png" />}
            to={`https://www.linkedin.com/search/results/companies?keywords=${field.name ?? field.tld}`}
            target="_blank"
            onClick={(e?: MouseEvent) => e?.stopPropagation()}
          />
        ),
      },
    ];
  }, [columns]);

  return (
    <InstantSearchTable<CompanyDetailType>
      columns={allColumns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No web traffic activity found</Trans>}
      error={error}
      rowKey={(record) => record.id}
      rowCursor="pointer"
      sticky
      scroll={{ x: 1200 }}
      getFieldName={(fieldId: string) =>
        getWebTrafficFieldName(`w.${fieldId}` as ALL_WEB_TRAFFIC_FIELDS)
      }
      onRow={(record) => ({
        onClick: () => onSelectedRowChange?.(record),
      })}
    />
  );
};

export default WebTrafficActivityTable;
