import { Trans } from '@lingui/react/macro';

import { OptionType } from '@/api/common';
import { Text } from '@/components/typography';

type Props = {
  options?: OptionType[];
  value?: string[] | string;

  // if true, ignore value and show "none" no matter what `value` is
  showAsEmpty?: boolean;
};

const EmptyField = ({ options, value, showAsEmpty }: Props) => {
  const opts = Array.isArray(value) ? value : value ? [value] : [];

  if (showAsEmpty || !opts.length) {
    return (
      <Text variant="body1" italic>
        <Trans>none</Trans>
      </Text>
    );
  }

  return (
    <Text variant="body1">
      {opts
        .map((opt) => {
          const option = options?.find((o) => o.id === opt);
          return option ? option.name : null;
        })
        .filter(Boolean)
        .join(', ')}
    </Text>
  );
};

export default EmptyField;
