import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router';

import { useMultiplePixelTargetingEfficiency } from '@/api/pixel';
import Flare, {
  Axis,
  Column,
  FlareSeriesOptions,
  Legend,
  Tooltip,
  linkifyAxisLabels,
} from '@/components/Flare';
import AxisLabel from '@/components/Flare/guides/AxisLabel';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { AUDIENCE_PRIMARY, TAM_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  pixelIds?: string[];
};

const PixelMultiTargetingEfficiencyChart = ({ pixelIds }: Props) => {
  const navigate = useNavigate();
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const { pixelTargetingEfficiencyData, isLoading, error } = useMultiplePixelTargetingEfficiency(
    pixelIds,
    startDate,
    endDate,
  );

  const sanitizedData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!pixelTargetingEfficiencyData) {
      return undefined;
    }

    return [
      {
        id: 'tam',
        name: t`TAM`,
        zIndex: 2,
        data: pixelTargetingEfficiencyData.pixels.map((d) => ({
          amount: d.stats.tam / (d.stats.all - d.stats.unresolved),
          custom: { count: d.stats.tam },
        })),
      },
      {
        id: 'audience',
        name: t`Target Audience`,
        zIndex: 3,
        data: pixelTargetingEfficiencyData.pixels.map((d) => ({
          amount: d.stats.audience / (d.stats.all - d.stats.unresolved),
          custom: { count: d.stats.audience },
        })),
      },
    ];
  }, [pixelTargetingEfficiencyData]);

  return (
    <Panel
      size="L"
      title={<Trans>Targeting Efficiency</Trans>}
      infoPopover={{
        body: <Trans>% of Impressions served to your audience.</Trans>,
      }}
      noPadding
      style={{ height: 462 }}
    >
      <Flare
        data={sanitizedData}
        description={t`A percent-based comparison between multiple pixels by Target Audience, Total Addressable Market (TAM), and everything else.`}
        colors={[TAM_PRIMARY, AUDIENCE_PRIMARY]}
        isLoading={isLoading}
        error={error}
        postRender={(chart) => {
          linkifyAxisLabels(chart.xAxis[0], (url) => navigate(url));
        }}
      >
        <Legend reversed />
        <Column
          y="amount"
          position="dodge"
          skeletonLoaderOptions={{ numSeries: 3, numCategories: pixelIds?.length || 3 }}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value, { isPercent: true })}
        />
        <Axis
          position="bottom"
          categories={pixelTargetingEfficiencyData?.pixels.map((d) => d.pixel.name || '')}
          labelFormat={(item) => {
            const { pixel } = pixelTargetingEfficiencyData?.pixels[item.pos] || {};
            if (!pixel?.id || !pixel?.name) {
              return '';
            }
            return renderToStaticMarkup(
              <AxisLabel
                label={pixel?.name}
                linkUrl={ROUTES.pixelById(pixel.id)}
                secondaryLabel={pixel?.audience?.name}
              />,
            );
          }}
          useHTML
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(point) => point?.key}
          rowValueFormat={(point) => numberFormat(point?.custom?.count)}
          rowSecondaryValueFormat={(item) =>
            `(${numberFormat(item?.y ?? 0, {
              isPercent: true,
              precision: 1,
            })})`
          }
          rowValueLink={(point) => {
            if (!point) {
              return;
            }

            const pixel = pixelTargetingEfficiencyData?.pixels[point?.index];
            if (pixel?.pixel) {
              if (point.colorIndex === 0) {
                return ROUTES.webTrafficActivityWithParams({
                  'w.pixelIds': pixel.pixel.id ? [pixel.pixel.id] : undefined,
                  'w.inTam': 'true',
                });
              }

              if (point.colorIndex === 1) {
                return ROUTES.webTrafficActivityWithParams({
                  'w.pixelIds': pixel.pixel.id ? [pixel.pixel.id] : undefined,
                  'w.audienceIds': pixel.pixel.audience?.id
                    ? [pixel.pixel.audience?.id]
                    : undefined,
                });
              }
            }
          }}
          unstable_hasRouterContext={false}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default PixelMultiTargetingEfficiencyChart;
