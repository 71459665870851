import moment from 'moment';
import { useMemo } from 'react';

import { OfflineEvent } from '@/api/opportunity';
import TimelineChartTooltipEventLinks from '@/app/closed-won/TimelineChartTooltipEventLinks';
import { Text } from '@/components/typography';

type Props = {
  events: OfflineEvent[];
  name: string;
};

type DailyGroupedEvents = {
  [date: string]: OfflineEvent[];
};

const TimelineChartTooltipEvents = ({ events }: Props) => {
  const dailyGroupedEvents = useMemo(
    () =>
      events.reduce<DailyGroupedEvents>((memo, e) => {
        const date = moment(e.eventDate).format('YYYY-MM-DD');
        memo[date] ||= [];
        memo[date].push(e);
        return memo;
      }, {}),
    [events],
  );

  return (
    <tbody>
      <tr>
        <td>
          {Object.keys(dailyGroupedEvents).map((date) => {
            const dailyEvents = dailyGroupedEvents[date];
            return (
              <div key={date}>
                <Text variant="body2" color="white">
                  {moment(date).format('MMM D')}:{' '}
                </Text>
                <TimelineChartTooltipEventLinks events={dailyEvents} />
              </div>
            );
          })}
        </td>
      </tr>
    </tbody>
  );
};

export default TimelineChartTooltipEvents;
