import { t } from '@lingui/core/macro';

import { useMultiplePixelTargetingEfficiency } from '@/api/pixel';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  pixelIds?: string[];
};

const PixelMultiKPIMetrics = ({ pixelIds }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const { pixelTargetingEfficiencyData, isLoading, error } = useMultiplePixelTargetingEfficiency(
    pixelIds,
    startDate,
    endDate,
  );

  return (
    <KpiMetricGroup>
      {pixelIds?.map((id) => {
        const item = pixelTargetingEfficiencyData?.pixels.find((d) => d.pixel.id === id);

        return (
          <KpiMetric
            key={id}
            label={item ? t`${item.pixel.name ?? ''} - Impressions` : t`Impressions`}
            value={item && numberFormat(item.stats.all)}
            isLoading={isLoading}
            error={error}
          />
        );
      })}
    </KpiMetricGroup>
  );
};

export default PixelMultiKPIMetrics;
