import { t } from '@lingui/core/macro';

import CampaignOverviewContent from '@/app/campaigns/CampaignOverviewContent';
import { Flex } from '@/components/Flex';
import InstantSearch, { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
};

const CampaignOverview = () => {
  return (
    <Page title={t`Campaigns`} pageName={t`All Campaigns`}>
      <InstantSearch prefix="c" defaultQueryState={DEFAULT_QUERY_STATE}>
        <PageFilterProvider>
          <Flex vertical gap="large" fullWidth>
            <Flex justify="space-between">
              <PageDateRangeFilter />
              <PageAudienceFilter />
            </Flex>
            <CampaignOverviewContent />
          </Flex>
        </PageFilterProvider>
      </InstantSearch>
    </Page>
  );
};

export default CampaignOverview;
