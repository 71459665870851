import {
  AUDIENCE_MEMBERSHIP_SORT_KEYS,
  AUDIENCE_SORT_KEYS,
} from '@/router/searchParams/audienceSearchParams';
import { PAGINATION_PARAM_KEYS } from '@/router/searchParams/defaultPaginationParams';
import { NavigableRouteType, RouteSearchParamEnumType } from '@/router/types';

export const audienceImport = {
  path: '/app/audiences/import',
  description: 'A form to import a Salesforce audience by supplying a Salesforce report link.',
  navigable: true,
};

export const audienceCreate = {
  path: '/app/audiences/create',
  description:
    'A form to either upload a CSV or manually type domain names which make up a audience.',
  navigable: true,
};

export const audiences = {
  path: '/app/audiences',
  description:
    'Displays a list of all audiences in the system. Each audience is shown with the number of companies in the audience, the number of visits, and the status of each audience. Status indicates whether an error occurred during CSV upload or Salesforce ingestion.',
  exampleQuestions: [
    'Show me a list of audiences sorted by size descending',
    'I would like to see all audiences that start with "EMEA"',
  ],
  navigable: true,
  searchParams: {
    'a.page': {
      type: 'string',
      description: 'The page number to display',
    },
    'a.size': {
      type: 'string',
      description: 'The number of items to display per page',
    },
    'a.sortBy': {
      type: 'enum',
      values: ['created_at', 'created_by.name', 'metrics.size', 'metrics.visits', 'name', 'type'],
      default: 'created_at',
      description: 'The field to sort by',
    } satisfies RouteSearchParamEnumType<AUDIENCE_SORT_KEYS>,
    'a.sortOrder': {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description: 'The sort order',
    },
    'a.search': {
      type: 'string',
      description: 'A string to search for in the audience name',
    },
  },
} satisfies NavigableRouteType<PAGINATION_PARAM_KEYS<'a'>>;

export const audienceDetail = {
  path: '/app/audiences/:audience',
  description:
    'A detail page for an audience. Shows a list of companies in the audience, the number of visits of each company in the last 30 days and a date for the most recent visit.',
  navigable: true,
  pathParams: {
    audience: {
      type: 'string',
      description: 'The ID of the audience the user wishes to view',
      object: 'audience',
      required: true,
    },
  },
  searchParams: {
    'a.page': {
      type: 'string',
      description: 'The page number to display',
    },
    'a.size': {
      type: 'string',
      description: 'The number of items to display per page',
    },
    'a.sortBy': {
      type: 'enum',
      values: ['metrics.lastActivityDate', 'metrics.visits', 'name'],
      default: 'name',
      description: 'The field to sort by',
    } satisfies RouteSearchParamEnumType<AUDIENCE_MEMBERSHIP_SORT_KEYS>,
    'a.sortOrder': {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'asc',
      description: 'The sort order',
    },
    'a.search': {
      type: 'string',
      description: 'A string to search for in the audience name',
    },
  },
} satisfies NavigableRouteType<PAGINATION_PARAM_KEYS<'a'>>;

export const audienceDetailEdit = {
  path: '/app/audiences/:audience/edit',
  description: 'A form where the user can edit the audience name and description.',
  navigable: true,
  pathParams: {
    audience: {
      type: 'string',
      description: 'The ID of the audience the user wishes to edit',
      object: 'audience',
      required: true,
    },
  },
};

export const audienceById = (audienceId?: string) => `${audiences.path}/${audienceId}`;
export const audienceEditById = (audienceId?: string) => `${audienceById(audienceId)}/edit`;
