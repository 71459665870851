/* eslint-disable no-restricted-imports */
import { Checkbox as AntdCheckbox, CheckboxProps as AntdCheckboxProps } from 'antd';
import { CheckboxGroupProps as AntdCheckboxGroupProps } from 'antd/es/checkbox';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

export type CheckboxValueType = string | number | boolean;

export type CheckboxProps = Omit<AntdCheckboxProps, 'disabled'> & {
  isDisabled?: boolean;
};

export type CheckboxGroupProps = Omit<AntdCheckboxGroupProps, 'disabled'> & {
  vertical?: boolean;
  isDisabled?: boolean;
};

const Checkbox = ({ children, className, isDisabled, ...rest }: CheckboxProps) => (
  <AntdCheckbox {...rest} className={classNames(styles.checkbox, className)} disabled={isDisabled}>
    {children}
  </AntdCheckbox>
);

Checkbox.Group = ({ className, vertical = false, isDisabled, ...rest }: CheckboxGroupProps) => {
  return (
    <AntdCheckbox.Group
      className={classNames(styles.group, className, { [styles.vertical]: vertical })}
      {...rest}
      disabled={isDisabled}
    />
  );
};

export default Checkbox;
