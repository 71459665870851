import { Trans } from '@lingui/react/macro';

import { Flex } from '@/components/Flex';
import { Results } from '@/components/Results';
import { Button } from '@/components/buttons';
import { ROUTES } from '@/router';

const Error500 = () => {
  return (
    <Results
      status="error"
      title={<Trans>System error</Trans>}
      subtitle={
        <Trans>
          Oops, something went wrong. Try to refresh the page or please contact us if the problem
          persists. Sorry about that. We've logged the error for review.
        </Trans>
      }
      extra={
        <Flex vertical>
          <Button color="green" variant="primary" onClick={() => location.reload()}>
            <Trans>Reload the page</Trans>
          </Button>
          <Button to={ROUTES.app.path} color="black" variant="secondary">
            <Trans>Go to the homepage</Trans>
          </Button>
          <Button
            to={'https://support.channel99.com'}
            target="_blank"
            color="black"
            variant="secondary"
          >
            <Trans>Visit the support center</Trans>
          </Button>
        </Flex>
      }
      isFullPage
    />
  );
};

export default Error500;
