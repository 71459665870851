import { MessageDescriptor, i18n } from '@lingui/core';
import { msg } from '@lingui/core/macro';

import { WEB_TRAFFIC_SEARCH_PARAM_KEYS } from '@/router/searchParams/webTrafficSearchParams';

export type NON_QUERY_PARAM_FIELDS = 'w.name' | 'w.metrics.lastActivityDate';

export type ALL_WEB_TRAFFIC_FIELDS = WEB_TRAFFIC_SEARCH_PARAM_KEYS | NON_QUERY_PARAM_FIELDS;

const WEB_TRAFFIC_FIELD_MESSAGES: Record<ALL_WEB_TRAFFIC_FIELDS, MessageDescriptor> = {
  'w.audienceIds': msg`Audience`,
  'w.channelIds': msg`Channel`,
  'w.vendorIds': msg`Vendor`,
  'w.campaignIds': msg`Campaign`,
  'w.sector.id': msg`Industry`,
  'w.revRange.id': msg`Revenue Range`,
  'w.region.id': msg`Region`,
  'w.metrics.impressions': msg`Impressions`,
  'w.metrics.visits': msg`Visits`,
  'w.inTam': msg`In TAM`,
  'w.pixelIds': msg`Pixel`,
  'w.isEngaged': msg`Is Engaged`,
  'w.name': msg`Company`,
  'w.metrics.lastActivityDate': msg`Last Visit`,
};

export const getWebTrafficFieldName = (fieldId: ALL_WEB_TRAFFIC_FIELDS) =>
  i18n._(WEB_TRAFFIC_FIELD_MESSAGES[fieldId]);
