import { t } from '@lingui/core/macro';
import { Point, PointerEventObject } from 'highcharts';
import moment from 'moment';
import { useMemo } from 'react';

import {
  OfflineEvent,
  OpportunityType,
  useOpportunityNonDigitalEventsTrends,
} from '@/api/opportunity';
import ClosedWonNonDigitalEventsLoader from '@/app/closed-won/ClosedWonNonDigitalEventsLoader';
import TimelineChartTooltipEvents from '@/app/closed-won/TimelineChartTooltipEvents';
import Flare, {
  Axis,
  Bubble,
  ChartCallbackFunction,
  FlareSeriesOptions,
  Tooltip,
} from '@/components/Flare';
import { Panel } from '@/components/panels';
import { DateFormatMD } from '@/constants';
import { getUTCTime } from '@/helper/dateHelper';
import {
  COLOR_AQUA_300,
  COLOR_ORANGE_400,
  COLOR_PINK_400,
  COLOR_TEAL_400,
  COLOR_VIOLET_400,
  COLOR_YELLOW_300,
} from '@/styles/palette';

import styles from './ClosedWonNonDigitalEventsChart.module.scss';

const LOADING_HEIGHT = 240;
const MIN_CHART_HEIGHT = 150;
const CHART_PADDING = 32;
const ROW_HEIGHT = 36;
const PANEL_TITLE_HEIGHT = 60;

const LARGE_BUBBLE_RADIUS = 8;
const SMALL_BUBBLE_RADIUS = 5;

const COLORS = [
  COLOR_VIOLET_400,
  COLOR_ORANGE_400,
  COLOR_TEAL_400,
  COLOR_YELLOW_300,
  COLOR_PINK_400,
  COLOR_AQUA_300,
];

type Props = {
  opportunity?: OpportunityType;
  marginLeft: number;
  marginRight: number;
  onHoverChart?: (event: PointerEventObject, point: Point) => void;
  onHoverLeaveChart?: (event: MouseEvent) => void;
  onPostRenderChart?: ChartCallbackFunction;
};

type CustomPointData = {
  events: OfflineEvent[];
  seriesName: string;
  seriesId: string;
};

const ClosedWonNonDigitalEventsChart = ({
  opportunity,
  marginLeft,
  marginRight,
  onHoverChart,
  onHoverLeaveChart,
  onPostRenderChart,
}: Props) => {
  const { offlineTimeline, isLoading, error } = useOpportunityNonDigitalEventsTrends(
    opportunity?.id,
  );

  const chartData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (offlineTimeline == null) {
      return undefined;
    }

    return offlineTimeline.map((series, seriesIndex) => {
      return {
        id: series.campaignType.id,
        name: `${series.campaignType.vendor.name}: ${series.campaignType.name}`,
        data: series.data
          .filter((d) => d.events.length)
          .map((d) => ({
            className: d.events.length > 1 ? styles['bubble-large'] : styles['bubble-small'],
            date: d.sundayOfWeek,
            category: seriesIndex,
            radius: d.events.length > 1 ? LARGE_BUBBLE_RADIUS : SMALL_BUBBLE_RADIUS,
            custom: {
              events: d.events,
              seriesName: series.campaignType.vendor.name,
              seriesId: series.campaignType.vendor.id,
            } as CustomPointData,
          })),
      };
    });
  }, [offlineTimeline]);

  const opportunityStartDate = getUTCTime(offlineTimeline?.[0]?.data?.[0]?.sundayOfWeek);
  const opportunityEndDate = getUTCTime(
    offlineTimeline?.[0]?.data?.[offlineTimeline[0].data.length - 1]?.sundayOfWeek,
  );

  const calculatedHeight = offlineTimeline
    ? Math.max(CHART_PADDING * 2 + offlineTimeline.length * ROW_HEIGHT, MIN_CHART_HEIGHT)
    : LOADING_HEIGHT;

  return (
    <Panel
      title={t`Non-Digital Events`}
      size="L"
      verifyC99Tag
      style={{ height: calculatedHeight + PANEL_TITLE_HEIGHT }}
      noPadding
    >
      <Flare
        key={opportunity?.id}
        className={styles.chart}
        data={chartData}
        parseX={getUTCTime}
        description={t`A timeline chart which shows the dates of all non-digital events across channels`}
        isLoading={isLoading}
        error={error}
        chartMarginLeft={marginLeft}
        chartMarginRight={marginRight}
        postRender={onPostRenderChart}
        colors={COLORS}
        onHoverChart={onHoverChart}
        onHoverLeaveChart={onHoverLeaveChart}
      >
        <Bubble
          x="date"
          y="category"
          z="radius"
          sizeBy="width"
          minSize={10}
          maxSize={18}
          loader={<ClosedWonNonDigitalEventsLoader />}
          showInteractionHalo={false}
        />
        <Axis
          type="category"
          categories={chartData?.map((d) => d.name)}
          tickmarkPlacement="on"
          tickPositions={chartData?.map((_, i) => i)}
          gridlineWidth={8}
          position="right"
          reversed
          useHTML
          labelsNoWrap
        />
        <Axis
          type="datetime"
          position="bottom"
          crosshair="line"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
          baselineWidth={0}
          gridlineWidth={0}
          tickLength={0}
          labelDistance={0}
          min={opportunityStartDate && +opportunityStartDate}
          max={opportunityEndDate && +opportunityEndDate}
        />
        <Tooltip
          titleFormat={(point) => {
            const startDate = moment(point?.x);
            const endDate = moment(point?.x).add(6, 'd');
            return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}: ${point.custom?.seriesName}`;
          }}
          rowContent={(points) => (
            <TimelineChartTooltipEvents
              events={(points[0].custom as CustomPointData).events}
              name={(points[0].custom as CustomPointData).seriesName}
            />
          )}
        />
      </Flare>
    </Panel>
  );
};

export default ClosedWonNonDigitalEventsChart;
