import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router';

import { InstantSearchSortOrder, QueryState } from './types';
import {
  getQueryStateFromURLSearchParams,
  getURLSearchParamsFromQueryState,
  isQueryStateEqual,
} from './util/search-util';

interface InstanceSearchContextType {
  queryState: QueryState;
  updateQueryState: (newQueryState: Partial<QueryState>) => void;
}

interface InstantSearchProviderProps {
  /**
   * A prefix to add to each url parameter key. This is used to distinguish query params managed by
   * this InstantSearch instance. It is also helpful for when you have multiple instances of
   * InstantSearch on the same page and want to avoid conflicts between the query params.
   */
  prefix: string;
  defaultQueryState?: Partial<QueryState>;
  ignoredFields?: (keyof QueryState)[];
  onQueryStateChange?: (newQueryState: QueryState) => void;
}

const InstantSearchContext = createContext<InstanceSearchContextType | undefined>(undefined);

const InstantSearch = ({
  defaultQueryState = {},
  ignoredFields = [],
  prefix,
  onQueryStateChange,
  children,
}: PropsWithChildren<InstantSearchProviderProps>) => {
  const initialQueryState: QueryState = useMemo(
    () => ({
      page: 1,
      size: 20,
      sortOrder: InstantSearchSortOrder.ASC,
      sortBy: '',
      search: '',
      filters: [],
      ...defaultQueryState,
    }),
    [defaultQueryState],
  );
  const navigate = useNavigate();
  const [queryState, setQueryState] = useState<QueryState>(() =>
    getQueryStateFromURLSearchParams(
      new URLSearchParams(window.location.search),
      prefix,
      initialQueryState,
    ),
  );

  const handleQueryStateChange = (updatedQueryState: QueryState, options: NavigateOptions) => {
    // Only replace the search params if they are out of sync with the query state. If the user
    // performs an action that changes the query state, push the new query state to the URL.
    updateSearchParams(
      getURLSearchParamsFromQueryState(updatedQueryState, prefix, ignoredFields),
      options,
    );

    if (!isQueryStateEqual(queryState, updatedQueryState)) {
      setQueryState(updatedQueryState);
    }
  };

  const updateSearchParams = (
    updatedSearchParams: URLSearchParams,
    options: NavigateOptions = {},
  ) => {
    const searchParamsCopy = new URLSearchParams(window.location.search);

    // Update the search params with the new values.
    updatedSearchParams.forEach((value, key) => {
      searchParamsCopy.set(key, value);
    });

    // Write all the search params back to the url.
    const paramsString = Array.from(searchParamsCopy.entries()).reduce(
      (memo, [key, value], index) => {
        // Only write the search params that are not managed by this InstantSearch instance, or
        // have been updated.
        if (!key.startsWith(`${prefix}.`) || updatedSearchParams.has(key)) {
          return `${memo}${index > 0 ? '&' : '?'}${key}=${value}`;
        }
        return memo;
      },
      '',
    );

    if (searchParamsCopy.toString() !== window.location.search) {
      navigate(paramsString, options);
    }
  };

  const updateQueryState = (newQueryState: Partial<QueryState>) => {
    handleQueryStateChange({ ...queryState, ...newQueryState }, { replace: false });
  };

  // The query state changed due to user interaction
  useEffect(() => {
    onQueryStateChange?.(queryState);
  }, [queryState]);

  // The page reloaded, or user edited the url params by hand
  useEffect(() => {
    const updatedQueryState = getQueryStateFromURLSearchParams(
      new URLSearchParams(window.location.search),
      prefix,
      initialQueryState,
    );
    handleQueryStateChange(updatedQueryState, { replace: true });
  }, [window.location.search, initialQueryState]);

  return (
    <InstantSearchContext.Provider value={{ queryState, updateQueryState }}>
      {children}
    </InstantSearchContext.Provider>
  );
};

export const useInstantSearchState = () => {
  const context = useContext(InstantSearchContext);
  if (!context) {
    throw new Error('useInstantSearchState must be used with an InstanceSearchProvider');
  }
  return context;
};

export default InstantSearch;
