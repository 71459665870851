import { I18n } from '@lingui/core';
import { t } from '@lingui/core/macro';
import moment from 'moment';

export const getUTCTime = (date?: string) => {
  if (!date) {
    return 0;
  }

  const utcDate = parseUTCDate(date);
  return utcDate.getTime();
};

export const getTime = (date: string) => {
  return moment(date).toDate().getTime();
};

export const formatDate = (
  date?: string | number | null,
  format?: string,
  { isUTC = false } = {},
) => {
  if (!date) {
    return t`N/A`;
  }

  let momentDate = moment(date);
  if (isUTC) {
    momentDate = momentDate.utc();
  }
  return momentDate.format(format);
};

export const formatDateFromNow = (date?: string, withoutPrefix?: boolean) => {
  if (!date) {
    return t`N/A`;
  }
  return moment(date).fromNow(withoutPrefix);
};

export const parseUTCDate = (date: number | string | Date) => {
  const d = moment(date).toDate();
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
    d.getUTCMilliseconds(),
  );
};

export const dateFormatter = (
  i18n: I18n,
  date: number | string | Date | undefined | null,
  options: Intl.DateTimeFormatOptions,
) => {
  if (date == null) {
    return '';
  }
  const d = moment(date);
  if (!d.isValid()) {
    return '';
  }
  return i18n.date(d.toDate(), options);
};

export const dateFormatterUTC = (
  i18n: I18n,
  date: number | string | Date | undefined | null,
  options: Intl.DateTimeFormatOptions,
) => {
  if (date == null) {
    return '';
  }
  const utcDate = parseUTCDate(date);
  return dateFormatter(i18n, utcDate, options);
};

export const isLastMonthOfYear = (isoDate: string) => moment(isoDate).utc().month() === 11;
export const isCurrentMonth = (isoDate: string) => moment().utc().isSame(isoDate, 'month');
