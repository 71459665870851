import { t } from '@lingui/core/macro';
import { Fragment } from 'react/jsx-runtime';

import { OfflineEvent } from '@/api/opportunity';
import { Text } from '@/components/typography';

const MAX_EVENTS = 5;

type Props = {
  events: OfflineEvent[];
};

const TimelineChartTooltipEventLinks = ({ events }: Props) => {
  if (events.length === 1) {
    return (
      <Text id={events[0].eventUrl} variant="body2" color="white" underline pointer>
        {t`View`}
      </Text>
    );
  } else {
    const displayEvents = events.slice(0, MAX_EVENTS);

    return (
      <>
        {displayEvents.map((event, index) => (
          <Fragment key={event.eventId}>
            <span>{index > 0 && ', '}</span>
            <Text id={event.eventUrl} variant="body2" color="white" underline pointer>
              #{index + 1}
            </Text>
          </Fragment>
        ))}
        {events.length > MAX_EVENTS && (
          <Text variant="body2" color="white">
            {' '}
            (of {events.length})
          </Text>
        )}
      </>
    );
  }
};

export default TimelineChartTooltipEventLinks;
