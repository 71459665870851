/* eslint-disable no-restricted-imports */
import { Button as AntdButton, ConfigProvider } from 'antd';
import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';
import { Link, To } from 'react-router';

import LoadingIndicator from '@/components/LoadingIndicator';
import { COLOR_GRAY_800, COLOR_GREEN_500, COLOR_RED_100, COLOR_WHITE } from '@/styles/palette';

import { ButtonHTMLProps } from '../types';
import styles from './Button.module.scss';

const dummyClickHandler = (e: MouseEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

type BaseButtonProps = Omit<ButtonHTMLProps, 'href'> & {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  isLoading?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  icon?: ReactNode;
  autoFocus?: boolean;
  block?: boolean;
  to?: To | false;
};

type SimpleButtonProps = BaseButtonProps & {
  color: 'white';
  variant?: never;
};

type VariantButtonProps = BaseButtonProps & {
  color: 'green' | 'black' | 'red';
  variant?: 'primary' | 'secondary';
};

export type ButtonProps = SimpleButtonProps | VariantButtonProps;

const getPrimaryColor = (color: 'black' | 'green' | 'red' | 'white') => {
  switch (color) {
    case 'green':
      return COLOR_GREEN_500;
    case 'white':
      return COLOR_WHITE;
    case 'red':
      return COLOR_RED_100;
    default:
      return COLOR_GRAY_800;
  }
};

const Button = ({
  className,
  children,
  color,
  variant = 'primary',
  type = 'button',
  size = 'medium',
  isLoading = false,
  isDisabled = false,
  isSelected = false,
  block = false,
  icon,
  autoFocus,
  to,
  target,
  rel,
  onClick,
  ...rest
}: ButtonProps) => {
  const hasIcon = icon != null;
  const isIconOnly = children == null && hasIcon;
  const hasVariant = color !== 'white';

  const button = (
    <ConfigProvider theme={{ token: { colorPrimary: getPrimaryColor(color) } }}>
      <AntdButton
        {...rest}
        className={classNames(
          styles.button,
          styles[`color-${color}`],
          styles[`size-${size}`],
          {
            [styles[`variant-${variant}`]]: hasVariant,
            [styles['icon-only']]: isIconOnly,
            [styles['has-icon']]: hasIcon,
            [styles.loading]: isLoading,
            [styles.selected]: isSelected,
            [styles.block]: block,
          },
          className,
        )}
        disabled={isDisabled}
        icon={isLoading ? <LoadingIndicator /> : icon}
        htmlType={type}
        autoFocus={autoFocus}
        shape="default"
        aria-pressed={isSelected}
        onClick={!isDisabled && !isLoading ? onClick : dummyClickHandler}
      >
        {children && <span className={styles.content}>{children}</span>}
      </AntdButton>
    </ConfigProvider>
  );

  if (to && !isDisabled) {
    return (
      <Link
        className={styles.link}
        to={to}
        target={target}
        rel={!rel && target === '_blank' ? 'noopener noreferrer' : rel}
      >
        {button}
      </Link>
    );
  }

  return button;
};

export default Button;
