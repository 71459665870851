import { t } from '@lingui/core/macro';
import { List as AntdList } from 'antd';
import classNames from 'classnames';
import { createContext, useMemo } from 'react';

import { OptionType } from '@/api/common';
import { Text } from '@/components/typography';
import useControlled from '@/hooks/useControlled';

import styles from './List.module.scss';
import ListItem from './ListItem';
import { ListContextType, ListProps } from './types';

const SYSTEM_STATUS_ID = 'C99-STATUS';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListContext = createContext<ListContextType<any>>({
  value: [],
  onChange: () => {},
  selectionMode: 'none',
  isDisabled: false,
});

const List = <T extends OptionType>({
  className,
  defaultValue,
  value: valueFromProps,
  options,
  renderItem,
  selectionMode = 'none',
  isLoading = false,
  isLoadingMore = false,
  isDisabled = false,
  error,
  onChange: onChangeFromProps,
  ...rest
}: ListProps<T>) => {
  const [value, onChange] = useControlled(valueFromProps, defaultValue, onChangeFromProps);

  const listOptions = useMemo(() => {
    if (error) {
      return [
        { id: SYSTEM_STATUS_ID, name: t`Unable to load items, please refresh and try again` } as T,
      ];
    }

    if (isLoading) {
      return [{ id: SYSTEM_STATUS_ID, name: t`Loading...` } as T];
    }

    if (isLoadingMore) {
      return options?.concat({ id: SYSTEM_STATUS_ID, name: t`Loading...` } as T);
    }

    return options;
  }, [options, isLoading, isLoadingMore, error]);

  const handleRenderItem = (item: T) => {
    if (item.id === SYSTEM_STATUS_ID) {
      return (
        <ListItem id={item.id} interactive={false}>
          <Text variant="body2" color="grey" italic>
            {item.name}
          </Text>
        </ListItem>
      );
    }

    return renderItem?.(item);
  };

  return (
    <ListContext.Provider value={{ value, onChange, isDisabled, selectionMode }}>
      <AntdList
        className={classNames(styles.list, className)}
        dataSource={listOptions}
        renderItem={handleRenderItem}
        locale={{ emptyText: t`No items found` }}
        {...rest}
      />
    </ListContext.Provider>
  );
};

List.Item = ListItem;

export default List;
