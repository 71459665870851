import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { DashboardLayout, DashboardType } from '@/components/Dashboard';
import { usePageFilterContext } from '@/components/page';
import WidgetLibrary from '@/features/WidgetLibrary';
import { ROUTES } from '@/router';
import { WEB_TRAFFIC_ACTIVITY_SORT_KEYS } from '@/router/searchParams/webTrafficSearchParams';

const WebTrafficDashboard = () => {
  const { pageFilters } = usePageFilterContext();
  const { audience } = pageFilters;

  const dashboard = useMemo<DashboardType>(
    () => ({
      id: 'webTraffic',
      widgets: [
        {
          id: '0',
          type: 'cell',
          title: t`Traffic Summary`,
          library: WidgetLibrary.TRAFFIC_TRENDS_WIDGET,
          showNotes: true,
          gridPos: { x: 0, y: 0, w: 24, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '1',
          type: 'cell',
          title: t`Total Visits`,
          library: WidgetLibrary.TRAFFIC_BREAKDOWN_BY_TYPE_WIDGET,
          gridPos: { x: 0, y: 6, w: 8, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '2',
          type: 'cell',
          title: audience?.name ? t`${audience?.name} Industries` : t`Industries`,
          library: WidgetLibrary.TRAFFIC_BREAKDOWN_BY_INDUSTRY_WIDGET,
          gridPos: { x: 8, y: 6, w: 8, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '3',
          type: 'cell',
          title: audience?.name ? t`${audience?.name} Company Size` : t`Company Size`,
          library: WidgetLibrary.TRAFFIC_BREAKDOWN_BY_COMPANY_SIZE_WIDGET,
          gridPos: { x: 16, y: 6, w: 8, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '4',
          type: 'cell',
          title: t`Top Companies`,
          library: WidgetLibrary.COMPANY_ACTIVITY_TOP_VISITS_WIDGET,
          link: {
            url: ROUTES.webTrafficActivityWithParams({
              'w.audienceIds': audience?.id,
              'w.sortBy': 'metrics.visits' satisfies WEB_TRAFFIC_ACTIVITY_SORT_KEYS,
              'w.sortOrder': 'desc',
            }),
            title: t`Company Activity`,
          },
          gridPos: { x: 0, y: 12, w: 24, h: 10 },
          verifyC99Tag: true,
        },
      ],
    }),
    [audience],
  );
  return <DashboardLayout dashboard={dashboard} isEditable={false} />;
};

export default WebTrafficDashboard;
