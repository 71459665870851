import { SortOrder } from 'antd/es/table/interface';
import isEqual from 'lodash/isEqual';

import { InstantSearchSortOrder, QueryState, SolQueryParams, SolQueryParamsNew } from '../types';
import {
  addURLSearchParamsFromFilters,
  getFiltersFromURLSearchParams,
  getSolQueryFiltersFromQueryParamsFilters,
  stringifyFilter,
} from './filter-util';

export const isQueryStateEqual = (qs1: QueryState, qs2: QueryState) => isEqual(qs1, qs2);

/**
 * See if the URLSearchParams are in-sync with `queryState`. This should only happen on an initial
 * load of the page. After the first load, we'll be in sync as the user changes the state.
 *
 * We don't care if `searchParams` has extra parameters. We just want it to have the minimum
 * needed to properly represent `queryState`.
 */
export const isSearchParamsOutOfSync = (searchParams: URLSearchParams, queryState: QueryState) => {
  const { filters, ...rest } = queryState;
  const queryStateKeys = Object.keys(rest) as Array<keyof QueryState>;

  return (
    queryStateKeys.some((key) => searchParams.get(key) !== (queryState[key] || null)) ||
    filters.some((filter) => searchParams.get(filter.field) !== stringifyFilter(filter))
  );
};

export const getURLSearchParamsFromQueryState = (
  queryState: QueryState,
  prefix: string,
  ignoredFields: (keyof QueryState)[],
): URLSearchParams => {
  const urlParams = new URLSearchParams();

  if (ignoredFields.indexOf('page') === -1) {
    urlParams.set(`${prefix}.page`, queryState.page + '');
  }
  if (ignoredFields.indexOf('size') === -1) {
    urlParams.set(`${prefix}.size`, queryState.size + '');
  }
  if (ignoredFields.indexOf('sortBy') === -1) {
    urlParams.set(`${prefix}.sortBy`, queryState.sortBy);
  }
  if (ignoredFields.indexOf('sortOrder') === -1) {
    urlParams.set(`${prefix}.sortOrder`, queryState.sortOrder);
  }

  if (queryState.search) {
    urlParams.set(`${prefix}.search`, queryState.search);
  }

  addURLSearchParamsFromFilters(urlParams, prefix, queryState.filters);

  return urlParams;
};

export const getQueryStateFromURLSearchParams = (
  searchParams: URLSearchParams,
  prefix: string,
  initialQueryState: Required<QueryState>,
): QueryState => {
  const searchParamsCopy = new URLSearchParams(searchParams.toString());

  const page = +(searchParamsCopy.get(`${prefix}.page`) || initialQueryState.page);
  const size = +(searchParamsCopy.get(`${prefix}.size`) || initialQueryState.size);
  const search = searchParamsCopy.get(`${prefix}.search`) || initialQueryState.search;
  const sortBy = searchParamsCopy.get(`${prefix}.sortBy`) || initialQueryState.sortBy;

  let sortOrder = initialQueryState.sortOrder;
  if (searchParamsCopy.has(`${prefix}.sortOrder`)) {
    sortOrder = getQueryStateSortOrder(searchParamsCopy.get(`${prefix}.sortOrder`));
  }

  // Delete known search params and assume the rest are filters
  searchParamsCopy.delete(`${prefix}.page`);
  searchParamsCopy.delete(`${prefix}.size`);
  searchParamsCopy.delete(`${prefix}.search`);
  searchParamsCopy.delete(`${prefix}.sortBy`);
  searchParamsCopy.delete(`${prefix}.sortOrder`);

  const filters = getFiltersFromURLSearchParams(
    searchParamsCopy,
    prefix,
    initialQueryState.filters,
  );

  return { page, size, sortBy, sortOrder, search, filters };
};

export const getSolQueryParamsNewFromQueryState = (
  queryState?: Required<QueryState>,
): SolQueryParamsNew | undefined => {
  if (!queryState) {
    return undefined;
  }

  return {
    page: {
      offset: (queryState.page - 1) * queryState.size,
      limit: queryState.size,
    },
    sort: {
      field: queryState.sortBy,
      direction: queryState.sortOrder,
    },
    filter: getSolQueryFiltersFromQueryParamsFilters(queryState.filters),
    searchQuery: queryState.search,
  };
};

/**
 * @deprecated Use the new version with support for `filters` and `searchQuery`.
 */
export const getSolQueryParamsFromQueryState = (
  queryState: Required<QueryState>,
): SolQueryParams => {
  return {
    page: {
      offset: (queryState.page - 1) * (queryState?.size || 0),
      limit: queryState.size,
    },
    sort: {
      field: queryState.sortBy,
      direction: queryState.sortOrder,
    },
    filter: queryState.search,
  };
};

export const getQueryStateSortOrder = (order?: SortOrder | string): InstantSearchSortOrder => {
  if (order === 'descend' || order === 'desc') {
    return InstantSearchSortOrder.DESC;
  }
  // order === 'ascend'
  return InstantSearchSortOrder.ASC;
};

export const getAntDSortOrder = (order: InstantSearchSortOrder): SortOrder => {
  if (order === InstantSearchSortOrder.DESC) {
    return 'descend';
  }
  return 'ascend';
};
