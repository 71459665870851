import { CSSProperties, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import SkeletonLoader from '@/components/SkeletonLoader';

import { FlareOptionsPositionValue } from '../types';

type Props = {
  numSeries?: number;
  numCategories?: number;
  sortDescending?: boolean;
  position: FlareOptionsPositionValue;
  style?: CSSProperties;
};

const ColumnLoader = ({
  numSeries = 2,
  numCategories = 3,
  sortDescending = false,
  position = 'stack',
  style,
}: Props) => {
  const isStacked = position === 'overlap' || position === 'stack' || position === 'percent';
  const barWidth = 25;
  const categoryGap = isStacked ? 10 : 30;
  const seriesGap = 3;
  const stackGap = 1;
  let xCursor = categoryGap / 2;
  let yCursor = 0;
  let maxYValue = 0;

  // generate the fake data once and reuse it every time we load for the duration of this
  // component. This will ensure we don't have jumps when we re-query data.
  const fakeData = useMemo(() => {
    const data = [...Array(numCategories)].map(() =>
      [...Array(numSeries)].map(() => Math.floor(Math.random() * 60 + 40)),
    );

    if (position === 'percent') {
      return data.map((series) =>
        series.map((point) => {
          const seriesTotal = series.reduce((a, b) => a + b);
          return Math.floor((point / seriesTotal) * 100);
        }),
      );
    }
    return data;
  }, [numSeries, numCategories]);

  const sortedFakeData = useMemo(() => {
    if (sortDescending) {
      return fakeData.sort(
        (a, b) => b.reduce((acc, val) => acc + val, 0) - a.reduce((acc, val) => acc + val, 0),
      );
    }
    return fakeData;
  }, [fakeData, sortDescending]);

  const renderColumn = (value: number, categoryIndex: number, seriesIndex: number) => {
    let bar;
    const key = `${categoryIndex}-${seriesIndex}`;
    const columnProps = {
      x: xCursor,
      height: value,
      width: barWidth,
    };

    if (isStacked) {
      bar = <rect key={key} {...columnProps} y={yCursor} />;
      yCursor += value + stackGap;
    } else {
      bar = <rect key={key} {...columnProps} y={0} />;
      xCursor += barWidth + seriesGap;
      maxYValue = Math.max(maxYValue, value);
    }
    return bar;
  };

  const renderCategory = (data: number[], categoryIndex: number) => {
    const category = data.map((d, i) => renderColumn(d, categoryIndex, i));

    if (isStacked) {
      yCursor -= seriesGap;
      maxYValue = Math.max(yCursor, maxYValue);
      yCursor = 0;
      xCursor += barWidth;
    } else {
      xCursor -= seriesGap;
    }

    xCursor += categoryGap;

    return category;
  };

  const children = sortedFakeData.map((d, i) => renderCategory(d, i));
  xCursor -= categoryGap / 2;

  return (
    <div style={{ margin: 32, flex: 1, overflow: 'hidden' }}>
      <AutoSizer>
        {({ height, width }) => (
          <SkeletonLoader
            style={{ ...style, transform: 'scale(1,-1)' }}
            viewBox={`0 0 ${xCursor} ${position === 'percent' ? 100 : maxYValue}`}
            preserveAspectRatio="none"
            height={height}
            width={width}
          >
            {children}
          </SkeletonLoader>
        )}
      </AutoSizer>
    </div>
  );
};

export default ColumnLoader;
