import {
  ALL_VENDORS_SEARCH_PARAM_KEYS,
  VENDORS_SORT_KEYS,
} from '@/router/searchParams/vendorSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from '@/router/types';

export const vendors = {
  path: '/app/vendors',
  description:
    'Displays metrics for each vendor. An overview of each vendor is shown with total spend, pixel impressions, pixel click-throughs, visits, companies who have engaged with the company, pipeline opportunities influenced, and closed-won opportunities influenced. The vendor can be broken down by the audiences that are targeted through that vendor. A fit score visualization is also displayed which shows how well the vendor is performing relative to other vendors in the same space.',
  navigable: true,
  searchParams: {
    'v.audienceId': {
      type: 'string',
      description: 'An audience ID to filter the vendors by',
      object: 'audience',
    },
    'v.channel.id': {
      type: 'string[]',
      description: 'A comma-separated string of channel IDs',
      object: 'channel',
    },
    'v.vendorId': {
      type: 'string[]',
      description: 'A comma-separated string of vendor IDs',
      object: 'vendor',
    },
    'v.spend': {
      type: 'range',
      description: 'A range of ad spend from a min to max value.',
    },
    'v.adImpressions': {
      type: 'range',
      description: 'A range of ad impressions from a min to max value.',
    },
    'v.adClicks': {
      type: 'range',
      description: 'A range of ad clicks from a min to max value.',
    },
    'v.visits': {
      type: 'range',
      description: 'A range of visits from a min to max value.',
    },
    'v.visitEfficiency': {
      type: 'range',
      description:
        'A range of visit efficiency from a min to max value. This is a percentage field which can range from 0 to 1.',
    },
    'v.viewThroughVisits': {
      type: 'range',
      description: 'A range of view-through visits from a min to max value.',
    },
    'v.engagedCompanies': {
      type: 'range',
      description: 'A range of engaged companies from a min to max value.',
    },
    'v.pipelineInfluenced': {
      type: 'range',
      description:
        'A range of pipeline influenced from a min to max value. This is a currency field which indicates the amount of possible revenue influenced by the campaign.',
    },
    'v.closedWonInfluenced': {
      type: 'range',
      description:
        'A range of closed-won influenced from a min to max value. This is a currency field which indicates the amount of revenue influenced by the campaign that has been closed as won.',
    },
    'v.sortBy': {
      type: 'enum',
      values: [
        'channel.id',
        'vendorId',
        'spend',
        'adImpressions',
        'adClicks',
        'visits',
        'viewThroughVisits',
        'visitEfficiency',
        'engagedCompanies',
        'pipelineInfluenced',
        'closedWonInfluenced',
      ],
      default: 'visits',
      description: 'The field to sort by',
    } satisfies RouteSearchParamEnumType<VENDORS_SORT_KEYS>,
    'v.sortOrder': {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description: 'The sort order',
    },
    'v.page': {
      type: 'string',
      description: 'The page number to display',
    },
    'v.size': {
      type: 'string',
      description: 'The number of items to display per page',
    },
  },
} satisfies NavigableRouteType<ALL_VENDORS_SEARCH_PARAM_KEYS>;

export const vendorDetail = {
  path: '/app/vendors/:vendor',
  description:
    'Display metrics for an individual vendor within a marketing channel. Total visits, total spend, and spend per visit are shown. The vendor can be broken down by the audiences that are targeted through that vendor.',
  navigable: true,
  pathParams: {
    vendor: {
      type: 'string',
      description: 'The ID of the vendor the user wishes to view',
      object: 'vendor',
      required: true,
    },
  },
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the vendor metrics by',
      object: 'audience',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const vendorById = (vendorId?: string) =>
  vendorId && `${vendors.path}/${encodeURIComponent(vendorId)}`;
