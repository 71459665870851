import { Plural, Trans } from '@lingui/react/macro';
import moment from 'moment';
import { useMemo, useState } from 'react';

import {
  CompanyDetailType,
  useWebTrafficActivityList,
  useWebTrafficActivityListCSVDownload,
} from '@/api/activity';
import AudienceFilter from '@/app/web-traffic-activity/AudienceFilter';
import RegionFilter from '@/app/web-traffic-activity/RegionFilter';
import RevenueRangeFilter from '@/app/web-traffic-activity/RevenueRangeFilter';
import SectorFilter from '@/app/web-traffic-activity/SectorFilter';
import { getWebTrafficFieldName } from '@/app/web-traffic-activity/webTrafficFields';
import { Flex } from '@/components/Flex';
import { useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { ColumnsType, FilterDropdown } from '@/components/Table';
import { RangeFilter } from '@/components/filters';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Tag } from '@/components/typography';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, USDateViewFormat } from '@/constants';
import { FileDownloadModal } from '@/features/FileDownloadModal';
import MetricSelector from '@/features/MetricSelector/MetricSelector';
import { numberFormat } from '@/helper/numberFormatter';
import { getRevenueRangeDescription } from '@/messages/revenue-range-name-messages';
import TableState from '@/providers/TableState/TableState';
import { ROUTES } from '@/router';

import ActivityActionMenu from './ActivityActionMenu';
import WebTrafficActivityDetailDrawer from './WebTrafficActivityDetailDrawer';
import WebTrafficActivityTable from './WebTrafficActivityTable';

const ActivityOverviewTable = () => {
  const { queryState } = useInstantSearchState();
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const [selectedRow, setSelectedRow] = useState<CompanyDetailType | null>(null);
  const [metricSelectorOpen, setMetricSelectorOpen] = useState(false);

  const { companies, totalResults, isLoading, error } = useWebTrafficActivityList(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
  );

  const {
    isLoading: isDownloadingFile,
    beginDownload,
    cancelDownload,
  } = useWebTrafficActivityListCSVDownload(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
  );

  const sortFields = ROUTES.webTrafficActivity.searchParams['w.sortBy'].values;

  const companyActivityMetrics = useMemo<ColumnsType<CompanyDetailType>>(
    () => [
      {
        title: getWebTrafficFieldName('w.region.id'),
        key: 'region.id',
        sorter: sortFields.indexOf('region.id') >= 0,
        align: 'right',
        width: 120,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RegionFilter />
          </FilterDropdown>
        ),
        render: (text, field) => field.region?.name,
      },
      {
        title: getWebTrafficFieldName('w.sector.id'),
        key: 'sector.id',
        sorter: sortFields.indexOf('sector.id') >= 0,
        width: 110,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <SectorFilter />
          </FilterDropdown>
        ),
        render: (text, field) => field.sector?.name,
      },
      {
        title: getWebTrafficFieldName('w.revRange.id'),
        key: 'revRange.id',
        sorter: sortFields.indexOf('revRange.id') >= 0,
        align: 'right',
        width: 110,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RevenueRangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => getRevenueRangeDescription(field.revRange?.id),
      },
      {
        title: getWebTrafficFieldName('w.audienceIds'),
        key: 'audienceIds',
        align: 'right',
        width: 130,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <AudienceFilter />
          </FilterDropdown>
        ),
        render: (text, field) => (
          <Flex gap="xsmall" justify="end">
            {field.audiences.length > 0 && (
              <Tag size="medium" color={AUDIENCE_PRIMARY} noMargin>
                <Plural value={field.audiences.length} one="# Audience" other="# Audiences" />
              </Tag>
            )}
            {field.inTam && (
              <Tag size="medium" color={TAM_PRIMARY} noMargin>
                <Trans>TAM</Trans>
              </Tag>
            )}
          </Flex>
        ),
      },
      {
        title: getWebTrafficFieldName('w.metrics.impressions'),
        key: 'metrics.impressions',
        sorter: sortFields.indexOf('metrics.impressions') >= 0,
        align: 'right',
        width: 95,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => numberFormat(field.metrics?.impressions),
      },
      {
        title: getWebTrafficFieldName('w.metrics.visits'),
        key: 'metrics.visits',
        sorter: sortFields.indexOf('metrics.visits') >= 0,
        align: 'right',
        width: 65,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => numberFormat(field.metrics?.visits),
      },
      {
        title: getWebTrafficFieldName('w.metrics.lastActivityDate'),
        key: 'metrics.lastActivityDate',
        align: 'right',
        sorter: sortFields.indexOf('metrics.lastActivityDate') >= 0,
        width: 80,
        defaultSortOrder: 'descend',
        render: (text, field) =>
          field.metrics?.lastActivityDate
            ? moment(field.metrics?.lastActivityDate).format(USDateViewFormat)
            : `\u2013`,
      },
    ],
    [],
  );

  return (
    <TableState columns={companyActivityMetrics}>
      <Panel
        title={<Trans>Company Activity</Trans>}
        actions={
          <ActivityActionMenu
            totalResults={totalResults}
            isLoading={isLoading}
            isDownloadingCSV={isDownloadingFile}
            onDownloadCSV={beginDownload}
            onOpenMetricSelector={() => setMetricSelectorOpen(true)}
          />
        }
        size="L"
        isFullHeight
      >
        <WebTrafficActivityTable
          data={companies}
          isLoading={isLoading}
          error={error}
          totalResults={totalResults}
          onSelectedRowChange={setSelectedRow}
        />
        <WebTrafficActivityDetailDrawer
          data={selectedRow}
          startDate={startDate}
          endDate={endDate}
          onClose={() => setSelectedRow(null)}
        />
        <FileDownloadModal open={isDownloadingFile} onAbortDownload={cancelDownload} />
        <MetricSelector open={metricSelectorOpen} onClose={() => setMetricSelectorOpen(false)} />
      </Panel>
    </TableState>
  );
};

export default ActivityOverviewTable;
