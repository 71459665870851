import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import 'highcharts';
import 'highcharts/highcharts-more';
import 'highcharts/modules/accessibility';
import { RouterProvider } from 'react-router';

import { useFlags } from '@/constants/featureFlags';
import { HeaderProvider } from '@/hooks/header/HeaderContext';
import { messages } from '@/locales/en/messages.mjs';
import { AntDConfigProvider } from '@/providers/AntD';
import { NotificationProvider } from '@/providers/Notification';
import { ThemeProvider } from '@/providers/Theme';
import { UserProvider } from '@/providers/User';
import getRouter from '@/router/Router';

i18n.load('en', messages);
i18n.activate('en');

function App() {
  const flags = useFlags();
  const router = getRouter(flags);

  return (
    <UserProvider>
      <I18nProvider i18n={i18n}>
        <AntDConfigProvider>
          <ThemeProvider>
            <NotificationProvider>
              <HeaderProvider>
                <RouterProvider router={router} />
              </HeaderProvider>
            </NotificationProvider>
          </ThemeProvider>
        </AntDConfigProvider>
      </I18nProvider>
    </UserProvider>
  );
}

export default App;
