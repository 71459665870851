import { t } from '@lingui/core/macro';
import { Point, PointerEventObject } from 'highcharts';
import moment from 'moment';
import { useMemo } from 'react';

import { OpportunityType, useRecentDealVisitTrends } from '@/api/opportunity';
import Flare, { Area, Axis, ChartCallbackFunction, Legend, Tooltip } from '@/components/Flare';
import { Marker, MarkerDataType, Notes } from '@/components/Flare/annotation';
import { NotesPanel } from '@/components/panels';
import { getChannelColor } from '@/constants/colors/channel';
import { DateBEFormat, DateFormatMD } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

import ClosedWonMarkerTooltip from './ClosedWonMarkerTooltip';
import ClosedWonTrendSummaryColumn from './ClosedWonTrendSummaryColumn';

type Props = {
  opportunity?: OpportunityType;
  marginLeft: number;
  marginRight: number;
  onHoverChart?: (event: PointerEventObject, point: Point) => void;
  onHoverLeaveChart?: (event: MouseEvent) => void;
  onPostRenderChart?: ChartCallbackFunction;
};

const ClosedWonTrendChart = ({
  opportunity,
  marginLeft,
  marginRight,
  onHoverChart,
  onHoverLeaveChart,
  onPostRenderChart,
}: Props) => {
  const {
    recentDealVisitTrends: data,
    isLoading,
    error,
  } = useRecentDealVisitTrends(opportunity?.id);

  const opportunityStartDate = moment(data?.channelData?.[0]?.data?.[0]?.sundayOfWeek).utc();
  const opportunityEndDate = moment(
    data?.channelData?.[0]?.data?.[data.channelData[0].data.length - 1]?.sundayOfWeek,
  ).utc();

  const chartData = useMemo(() => {
    if (!data?.channelData || !opportunity) {
      return undefined;
    }

    return data?.channelData.map((series) => ({
      id: series.channel.id,
      name: series.channel.name,
      data: series.data,
    }));
  }, [data]);

  const markerData = useMemo<MarkerDataType[]>(
    () => [
      {
        id: 'open',
        label: t`Pipeline`,
        align: 'center',
        date: moment(opportunity?.openDate).utc().startOf('w').toISOString() ?? '',
      },
      {
        id: 'close',
        label: t`Closed`,
        align: 'right',
        date: moment(opportunity?.closeDate).utc().startOf('w').toISOString() ?? '',
      },
    ],
    [data],
  );

  return (
    <NotesPanel
      startDate={opportunity?.openDate && moment(opportunity.openDate).format(DateBEFormat)}
      endDate={opportunity?.closeDate && moment(opportunity.closeDate).format(DateBEFormat)}
      size="L"
      title={t`Visit Trends`}
      verifyC99Tag
      noPadding
      style={{ height: 625 }}
    >
      <Flare
        key={opportunity?.id}
        data={chartData}
        colors={data?.channelData.map((channel) => getChannelColor(channel.channel))}
        parseX={getUTCTime}
        description={t`A trend chart showing a running total of visits by channel stacked on top of each other`}
        isLoading={isLoading}
        error={error}
        marginTop={60}
        chartMarginLeft={marginLeft}
        chartMarginRight={marginRight}
        onHoverChart={onHoverChart}
        onHoverLeaveChart={onHoverLeaveChart}
        postRender={onPostRenderChart}
      >
        <Legend />
        <Area x="sundayOfWeek" y="runningTotalVisits" position="stack" />
        <Marker
          data={markerData}
          renderLabel={(marker: MarkerDataType) => (
            <ClosedWonMarkerTooltip data={marker} opportunity={opportunity} />
          )}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value)}
          tickPixelInterval={125}
        />
        <Axis
          type="datetime"
          position="bottom"
          crosshair="line"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
          min={getUTCTime(opportunityStartDate.toISOString())}
          max={getUTCTime(opportunityEndDate.toISOString())}
        />
        <Tooltip
          shared
          titleFormat={(point) => {
            const startDate = moment(opportunityStartDate);
            const endDate = moment(point?.x).add(6, 'd');
            return t`${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')} Visits`;
          }}
          rowValueFormat={(point) => numberFormat(point.y)}
          showTotalRow
        />
        <Notes />
        <ClosedWonTrendSummaryColumn width={marginRight} />
      </Flare>
    </NotesPanel>
  );
};

export default ClosedWonTrendChart;
