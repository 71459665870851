import classNames from 'classnames';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Layout, Layouts, Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import AutoSizer from 'react-virtualized-auto-sizer';

import { usePageFilterContext } from '@/components/page';

import styles from './DashboardLayout.module.scss';
import DashboardRowWidget from './DashboardRowWidget';
import DashboardWidget from './DashboardWidget';
import { DashboardType, DashboardWidgetRowType, DashboardWidgetType } from './types';

const isRowWidget = (widget: DashboardWidgetType): widget is DashboardWidgetRowType => {
  return 'children' in widget;
};

const BREAKPOINT_MEDIUM = 'md';
const BREAKPOINT_SMALL = 'xxs';

const breakpoints = {
  [BREAKPOINT_MEDIUM]: 769, // 1 more than regular ipad in portrait
  [BREAKPOINT_SMALL]: 0,
};
const cols = { [BREAKPOINT_MEDIUM]: 24, [BREAKPOINT_SMALL]: 1 };

const buildLayout = (data: DashboardType): Layout[] => {
  return data.widgets.map(({ id, gridPos }) => ({ i: id, ...gridPos }));
};

const updatePositions = (dashboard: DashboardType, gridLayout: Layout[]) => {
  return {
    ...dashboard,
    widgets: dashboard.widgets.map((widget, index) => {
      const newPosition = gridLayout[index];
      const { x, y, w, h } = newPosition;

      return {
        ...widget,
        gridPos: { x, y, w, h },
      };
    }),
  };
};

type Props = {
  className?: string;
  dashboard: DashboardType;
  isEditable?: boolean;
  isInteractive?: boolean;
  onLayoutChange?: (newDashboard: DashboardType) => void;
  rowHeight?: number;
};

const DashboardLayout = ({
  className = '',
  dashboard,
  rowHeight = 40,
  isEditable = true,
  onLayoutChange,
}: Props) => {
  const { pageFilters } = usePageFilterContext();
  const [animated, setAnimated] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [fullscreenWidgetId, setFullScreenWidgetId] = useState<string | null>(null);
  const draggableHandleSelector = styles.draggableHandle;
  const draggableIgnoreSelector = styles.draggableIgnoreHandle;

  const handleLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    const dashLayout = allLayouts.md;
    const updatedDashboard = updatePositions(dashboard, dashLayout);

    onLayoutChange?.(updatedDashboard);
  };

  const handleWidgetExpand = (isExpanded: boolean, widget: DashboardWidgetType) => {
    setFullScreenWidgetId(isExpanded ? widget.id : null);
  };

  useEffect(() => {
    // react-grid-layout will animate all cells to move to their first position on initial render
    // which looks a little janky. Setting a timer which enables position animation after initial
    // render fixes this -- now cells will just immediately appear in their initial position.
    const timeout = setTimeout(() => setAnimated(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (resizing) {
      document.body.classList.add(styles.resizing);
    } else {
      document.body.classList.remove(styles.resizing);
    }
    return () => document.body.classList.remove(styles.resizing);
  }, [resizing]);

  const children = useMemo(
    () =>
      dashboard.widgets.map((widget) => {
        const style: CSSProperties = {};
        let className = '';
        const isFullScreen = widget.id === fullscreenWidgetId;

        if (fullscreenWidgetId) {
          if (isFullScreen) {
            className = 'react-grid-item--fullScreen';
            style.width = '100%';
            style.height = '100%';
            style.display = 'block';
          } else {
            style.display = 'none';
          }
        }

        return (
          <div className={className} style={style} key={widget.id}>
            {isRowWidget(widget) ? (
              <DashboardRowWidget
                widget={widget}
                pageFilters={pageFilters}
                onWidgetExpand={handleWidgetExpand}
              />
            ) : (
              <DashboardWidget
                widget={widget}
                pageFilters={pageFilters}
                isFullScreen={isFullScreen}
                draggableHandleSelector={isEditable && draggableHandleSelector}
                draggableIgnoreSelector={isEditable && draggableIgnoreSelector}
                onWidgetExpand={handleWidgetExpand}
              />
            )}
          </div>
        );
      }),
    [dashboard.widgets, pageFilters, fullscreenWidgetId],
  );

  return (
    <div className={styles.wrapper}>
      <AutoSizer disableHeight>
        {({ width }) => {
          return (
            <ResponsiveGridLayout
              className={classNames(styles.layout, className, {
                [styles.animated]: animated,
                [styles.widgetInFullScreen]: fullscreenWidgetId != null,
              })}
              margin={[32, 32]}
              containerPadding={[0, 0]}
              layouts={{ [BREAKPOINT_MEDIUM]: buildLayout(dashboard) }}
              breakpoints={breakpoints}
              cols={cols}
              rowHeight={rowHeight}
              width={width}
              draggableHandle={`.${draggableHandleSelector}`}
              draggableCancel={`.${draggableIgnoreSelector}`}
              // Dragging is not allow in a single-column mobile-friendly layout. It's a poor UX.
              isDraggable={width >= breakpoints[BREAKPOINT_MEDIUM] && isEditable}
              isResizable={isEditable}
              onLayoutChange={handleLayoutChange}
              onResizeStart={() => setResizing(true)}
              onResizeStop={() => setResizing(false)}
            >
              {children}
            </ResponsiveGridLayout>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default DashboardLayout;
