import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Col, Row } from 'antd';

import { INTEGRATION_PROVIDER_ID } from '@/api/integration';
import {
  InstanceCampaignSettingsType,
  InstanceDetailDataType,
  InstanceLeadSettingsType,
} from '@/api/organization';
import CompanyNonDigitalTasksList from '@/app/company-settings/CompanyNonDigitalTasksList';
import EmptyField from '@/app/company-settings/EmptyField';
import { Flex } from '@/components/Flex';
import Form, { Select, Switch } from '@/components/Form';
import { Results } from '@/components/Results';
import { Button } from '@/components/buttons';
import { Panel } from '@/components/panels';
import { ROUTES } from '@/router';

import { FORM_FIELD, FormType } from './CompanySettings';

type Props = {
  isEditMode: boolean;
  isSaving: boolean;
  instance?: InstanceDetailDataType;
};

const CompanyNonDigitalEventsSettings = ({ isEditMode, isSaving, instance }: Props) => {
  const formInstance = Form.useFormInstance<FormType>();

  const includeTasks = Form.useWatch(FORM_FIELD.INCLUDE_TASKS, {
    form: formInstance,
    preserve: true,
  });
  const includeLeads = Form.useWatch(FORM_FIELD.INCLUDE_LEADS, {
    form: formInstance,
    preserve: true,
  });
  const includeCampaigns = Form.useWatch(FORM_FIELD.INCLUDE_CAMPAIGNS, {
    form: formInstance,
    preserve: true,
  });

  return (
    <Panel title={t`Non-Digital Events`} data-testid="non-digital-events-panel">
      {instance?.hasActiveSalesforceConnection ? (
        <Row gutter={[40, 12]}>
          <Col xs={24}>
            <Form.Item
              label={<Trans>Tasks & Events Reporting</Trans>}
              labelExtra={
                isEditMode && (
                  <Form.Item name={FORM_FIELD.INCLUDE_TASKS} noStyle>
                    <Switch aria-label={t`Include tasks and events`} isDisabled={isSaving} />
                  </Form.Item>
                )
              }
              name={FORM_FIELD.TASK_EVENT}
              infoPopover={{
                title: t`Tasks & Events Reporting`,
                body: (
                  <Trans>
                    Tasks and Events reporting originate primarily from sales tools automatically
                    logging calls and emails as well as manual logging within the Salesforce account
                    page. Unchecked types will be removed from company activity timelines and all
                    attribution calculations. Toggling "Off" will remove all tasks and events from
                    our system.
                  </Trans>
                ),
              }}
              initialValue={instance?.taskEvent}
            >
              <CompanyNonDigitalTasksList
                taskOptions={instance?.taskTypeOptions}
                isDisabled={isSaving}
                isEditMode={isEditMode}
                isHidden={!includeTasks}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={<Trans>Lead Status Tracking</Trans>}
              labelExtra={
                isEditMode && (
                  <Form.Item name={FORM_FIELD.INCLUDE_LEADS} noStyle>
                    <Switch aria-label={t`Include leads`} isDisabled={isSaving} />
                  </Form.Item>
                )
              }
              name={[
                FORM_FIELD.LEAD,
                'offlineEventsLeadStatus' satisfies keyof InstanceLeadSettingsType,
              ]}
              infoPopover={{
                title: t`Lead Status Tracking`,
                body: (
                  <Trans>
                    Select the stage you would like to use to start tracking leads. This is usually
                    the stage you consider the lead an MQL. If this stage is skipped within a lead
                    status, we will still apply the progressing stages date so the lead is not
                    missed. Toggling "Off" will remove all lead data from our system.
                  </Trans>
                ),
              }}
              rules={[
                { required: includeLeads && isEditMode, message: t`Lead Status is required` },
              ]}
              initialValue={instance?.lead?.offlineEventsLeadStatus}
            >
              {isEditMode ? (
                <Select
                  size="medium"
                  width={250}
                  placeholder={t`Select a Lead Status value`}
                  options={instance?.leadStatusOptions.map((val) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  notFoundContent={t`No lead stages were found`}
                  isDisabled={isSaving || !includeLeads}
                />
              ) : (
                <EmptyField options={instance?.leadStatusOptions} showAsEmpty={!includeLeads} />
              )}
            </Form.Item>
          </Col>
          <Col lg={10} md={24} sm={24} xs={24}>
            <Form.Item
              label={<Trans>Campaign Field</Trans>}
              labelExtra={
                isEditMode && (
                  <Form.Item name={FORM_FIELD.INCLUDE_CAMPAIGNS} noStyle>
                    <Switch aria-label={t`Include campaigns`} isDisabled={isSaving} />
                  </Form.Item>
                )
              }
              name={[FORM_FIELD.CAMPAIGN, 'typeField' satisfies keyof InstanceCampaignSettingsType]}
              infoPopover={{
                title: t`Campaign Field`,
                body: (
                  <Trans>
                    Choose the field used to categorize your campaigns. This field often includes
                    Field Events, Webinars, Email Campaigns and so on. Toggling "Off" will remove
                    campaign data from our system.
                  </Trans>
                ),
              }}
              rules={[
                {
                  required: includeCampaigns && isEditMode,
                  message: t`Campaign Field is required`,
                },
              ]}
              initialValue={instance?.campaign?.typeField}
            >
              {isEditMode ? (
                <Select
                  size="medium"
                  width={300}
                  placeholder={t`Select a Campaign Field`}
                  options={instance?.typeFieldOptions.map((val) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  notFoundContent={t`No campaign fields were found`}
                  isDisabled={isSaving || !includeCampaigns}
                />
              ) : (
                <EmptyField options={instance?.typeFieldOptions} showAsEmpty={!includeCampaigns} />
              )}
            </Form.Item>
          </Col>
          <Col lg={10} md={24} sm={24} xs={24}>
            <Form.Item
              label={<Trans>Campaign Spend Field</Trans>}
              name={[
                FORM_FIELD.CAMPAIGN,
                'spendField' satisfies keyof InstanceCampaignSettingsType,
              ]}
              infoPopover={{
                title: t`Campaign Spend Field`,
                body: (
                  <Trans>
                    Choose the field which reflects where your organization logs cost data. This
                    will be used in ROI reporting.
                  </Trans>
                ),
              }}
              rules={[
                {
                  required: includeCampaigns && isEditMode,
                  message: t`Campaign Spend Field is required`,
                },
              ]}
              initialValue={instance?.campaign?.spendField}
            >
              {isEditMode ? (
                <Select
                  size="medium"
                  width={300}
                  placeholder={t`Select a Campaign Spend Field`}
                  options={instance?.spendFieldOptions.map((val) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  notFoundContent={t`No campaign spend fields were found`}
                  isDisabled={isSaving || !includeCampaigns}
                />
              ) : (
                <EmptyField options={instance?.spendFieldOptions} showAsEmpty={!includeCampaigns} />
              )}
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Results
          subtitle={
            <Flex vertical align="center">
              <span>
                <Trans>A CRM connection is required for Non-Digital Events reporting.</Trans>
              </span>
              <span>
                <Trans>Please connect your CRM to activate this feature.</Trans>
              </span>
            </Flex>
          }
          extra={
            <Button color="green" to={ROUTES.integrationById(INTEGRATION_PROVIDER_ID.SALESFORCE)}>
              <Trans>Connect CRM</Trans>
            </Button>
          }
        />
      )}
    </Panel>
  );
};

export default CompanyNonDigitalEventsSettings;
