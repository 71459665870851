/* eslint-disable no-restricted-imports */
import { t } from '@lingui/core/macro';
import { Table as AntTable, ConfigProvider, TablePaginationConfig } from 'antd';
import { TableRef } from 'antd/es/table';
import { ColumnsType as AntdColumnsType } from 'antd/es/table/interface';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import Chevron from '@/assets/svg/chevron-left.svg?react';
import { FilterOperatorValue } from '@/components/InstantSearch';
import Spin from '@/components/Spin';
import { ActionIcon } from '@/components/buttons';
import WidgetError from '@/components/errors/WidgetError';

import ColumnTitle from './ColumnTitle';
import ExpandedRow from './ExpandedRow';
import Pagination from './Pagination';
import styles from './Table.module.scss';
import TableLoader from './TableLoader';
import { ColumnGroupType, ColumnType, ColumnsType, SortResult, TableProps } from './types';

type InnerColumnType<T> = Omit<ColumnType<T>, 'title'> & { title?: () => React.ReactNode };

const isColumnType = <T extends object>(
  e: ColumnType<T> | ColumnGroupType<T>,
): e is ColumnType<T> => (e as ColumnType<T>).key != null;

const patchColumnDataType = <T extends object>(column: ColumnType<T> | ColumnGroupType<T>) => {
  if (isColumnType(column)) {
    if (column.key && !column.dataIndex) {
      column.dataIndex = column.key;
    }
  } else {
    // ColumnGroupType (recursive)
    column.children.forEach((nestedColumn: ColumnType<T> | ColumnGroupType<T>) => {
      patchColumnDataType(nestedColumn);
    });
  }
};

// Crawl through `columns` to determine which sort is applied by default.
const collectSort = <T extends object>(columns: ColumnsType<T>): SortResult<T> | undefined => {
  let sort: SortResult<T> | undefined;

  (columns || []).forEach((column) => {
    // short-circuit if we have a controlled sort, otherwise keep looking
    if (sort && !sort.isDefault) {
      return;
    }

    if (isColumnType(column) && column.sorter) {
      const columnSortKey = column.getSortKey ? column.getSortKey() : column.key;

      if (column.sortOrder) {
        sort = {
          column,
          columnKey: columnSortKey,
          field: columnSortKey,
          order: column.sortOrder,
          isDefault: false,
        };
      } else if (column.defaultSortOrder && !sort) {
        // Default sorter
        sort = {
          column,
          columnKey: columnSortKey,
          field: columnSortKey,
          order: column.defaultSortOrder,
          isDefault: true,
        };
      }
    } else {
      sort = collectSort((column as ColumnGroupType<T>).children);
    }
  });

  return sort;
};

const collectFilters = <T extends object>(columns: ColumnsType<T>, init: boolean) => {
  let currentFilters: Record<string, FilterOperatorValue> = {};

  (columns || []).forEach((column) => {
    if ('filterDropdown' in column || 'onFilter' in column) {
      if ('filteredValue' in column) {
        // Controlled
        currentFilters[column.key] = column.filteredValue;
      } else if ('defaultFilteredValue' in column && init) {
        // Uncontrolled
        currentFilters[column.key] = column.defaultFilteredValue;
      }
    }

    if (!isColumnType(column)) {
      currentFilters = { ...currentFilters, ...collectFilters(column.children, init) };
    }
  });

  return currentFilters;
};

const Table = <T extends object>(
  {
    className,
    actionContent,
    columns,
    loading = false,
    pagination,
    dataSource,
    emptyMessage,
    error,
    expandable,
    loaderRows = 5,
    rowCursor = 'auto',
    showHeader,
    size,
    onChange,
    ...rest
  }: TableProps<T>,
  ref: ForwardedRef<TableRef>,
) => {
  const [sort, setSort] = useState<SortResult<T> | undefined>(collectSort(columns || []));
  const [filters, setFilters] = useState<Record<string, FilterOperatorValue>>(() =>
    collectFilters(columns || [], true),
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[] | undefined>(
    expandable?.expandedRowKeys,
  );

  useEffect(() => {
    if (columns) {
      setSort(collectSort(columns));
      setFilters(collectFilters(columns, false));
    }
  }, [columns]);

  // Hang on to the last successful response and don't reset the table when we go to `loading`
  // state and `data == null`. Instead, just display a small spinner over the stale data.
  const stickyDataSource = useRef<readonly T[]>();

  const expandableActual = useMemo<typeof expandable>(() => {
    if (expandable) {
      const retVal = {
        ...expandable,
        mode: expandable.mode || 'multiple',
      };

      if (!retVal.expandIcon) {
        retVal.expandIcon = ({ expanded, expandable, onExpand, record }) =>
          expandable ? (
            <ActionIcon
              className={classNames(styles.expandIcon, { [styles.expanded]: expanded })}
              icon={<Chevron />}
              aria-label={expanded ? t`Collapse` : t`Expand`}
              color="black"
              size="xsmall"
              onClick={(e) => onExpand(record, e)}
            />
          ) : null;
      }

      retVal.expandedRowKeys =
        retVal.mode === 'accordion'
          ? expandedRowKeys?.slice(expandedRowKeys.length - 1)
          : expandedRowKeys;

      const onExpandedRowsChange = expandable.onExpandedRowsChange;
      retVal.onExpandedRowsChange = (expandedRowKeys) => {
        setExpandedRowKeys(expandedRowKeys);
        onExpandedRowsChange?.(expandedRowKeys);
      };

      const expandedRowRender = expandable.expandedRowRender;
      retVal.expandedRowRender = (record, index, indent, expanded) => (
        <ExpandedRow
          record={record}
          index={index}
          indent={indent}
          isExpanded={expanded}
          durationMs={expandable.durationMs}
          expandedRowRender={expandedRowRender}
        />
      );

      return retVal;
    }
  }, [expandable, expandedRowKeys]);

  const patchedColumns = useMemo(
    () =>
      (columns ?? []).map(({ filterDropdown, ...col }) => {
        const newCol = { ...col } as InnerColumnType<T>;
        patchColumnDataType(newCol as ColumnType<T>);
        const columnSortKey = newCol.getSortKey ? newCol.getSortKey() : newCol.key;

        const columnSort = sort?.columnKey === columnSortKey ? sort : undefined;
        newCol.sortOrder = columnSort?.order;
        newCol.className = classNames(
          styles.column,
          newCol.className,
          columnSort && styles.columnSorted,
          !!filterDropdown && styles.filteredColumn,
        );

        newCol.title = () => {
          const isSortable = newCol.sorter != null;
          const isColSorted = columnSort != null;
          return (
            <ColumnTitle
              key={newCol.key}
              dataKey={newCol.key}
              title={col.title as string}
              align={newCol.align}
              isSortable={isSortable}
              isSorted={isColSorted}
              sortDirection={columnSort?.order}
              isFilterable={filterDropdown != null}
              filteredValue={filters[newCol.key]}
              filterDropdown={filterDropdown}
              onChange={(filter, order) => {
                let newFilters;
                if (!isEqual(filter, filters[newCol.key])) {
                  newFilters = { ...filters, [newCol.key]: filter };
                  setFilters(newFilters);
                }

                let newSort;
                if (order != columnSort?.order) {
                  newSort = {
                    column: newCol as ColumnType<T>,
                    columnKey: columnSortKey,
                    field: columnSortKey,
                    order,
                    isDefault: false,
                  };

                  setSort(newSort);
                }

                handleTableChange(pagination, newFilters ?? filters, newSort ?? sort);
              }}
            />
          );
        };

        return newCol;
      }),
    [columns, sort, filters],
  );

  const handleTableChange = (
    newPagination: false | TablePaginationConfig | undefined,
    newFilters: Record<string, FilterOperatorValue>,
    newSorter: SortResult<T> | undefined,
  ) => {
    if (newPagination != null && newPagination !== false) {
      const existingPagination = pagination || {};
      const shouldResetCurrentPage =
        newFilters !== filters ||
        newSorter !== sort ||
        newPagination.pageSize !== existingPagination.pageSize;
      if (shouldResetCurrentPage) {
        newPagination.current = 1;
      }
    }
    onChange?.(newPagination || {}, newFilters, newSorter);
  };

  const handlePaginationChange = (current: number, pageSize: number) => {
    handleTableChange({ ...pagination, current, pageSize }, filters, sort);
  };

  if (dataSource != null) {
    stickyDataSource.current = dataSource;
  }

  if (error) {
    // reset stickyDataSource when error happens so we can show the message on an empty single row
    stickyDataSource.current = [];
  }

  if (loading && stickyDataSource.current == null) {
    return (
      <div style={{ margin: 8 }}>
        <TableLoader
          size={size}
          hideHeader={showHeader === false}
          numColumns={columns?.length}
          numRows={loaderRows}
        />
      </div>
    );
  }

  return (
    <Spin spinning={!!loading && !error}>
      <ConfigProvider renderEmpty={() => (error ? <WidgetError /> : emptyMessage)}>
        <AntTable
          ref={ref}
          className={classNames(styles.table, className)}
          rowClassName={classNames(styles.row, styles[`cursor-${rowCursor}`])}
          {...rest}
          dataSource={stickyDataSource.current}
          columns={patchedColumns as AntdColumnsType<T>}
          pagination={false}
          showSorterTooltip={false}
          showHeader={showHeader}
          size={size === 'medium' ? 'middle' : size}
          expandable={expandableActual}
        />
        {(actionContent || pagination) && (
          <div data-testid="table-actions-content" className={styles.actionContainer}>
            <div className={styles.actionContent}>{!!actionContent && actionContent}</div>
            <div className={styles.pagination}>
              {!!pagination && (
                <Pagination
                  {...pagination}
                  data-testid="c99-pagination"
                  onChange={handlePaginationChange}
                />
              )}
            </div>
          </div>
        )}
      </ConfigProvider>
    </Spin>
  );
};

export default forwardRef(Table) as <T>(
  props: TableProps<T> & { ref?: ForwardedRef<TableRef> },
) => ReturnType<typeof Table>;
