import { Trans } from '@lingui/react/macro';
import { ReactNode } from 'react';

import { Flex } from '@/components/Flex';
import { Results } from '@/components/Results';
import { Button } from '@/components/buttons';
import { ROUTES } from '@/router';

const Error404 = ({ message }: { message?: ReactNode }) => {
  return (
    <Results
      status="warning"
      title={message ? message : <Trans>The page you're looking for can't be found.</Trans>}
      subtitle={
        <Trans>
          This can happen when you follow a link to something that has since been deleted. Check
          that you typed the address correctly, go back to your previous page or return to the home
          page.
        </Trans>
      }
      extra={
        <Flex vertical>
          <Button color="green" variant="primary" onClick={() => history.back()}>
            <Trans>Return to the previous page</Trans>
          </Button>
          <Button to={ROUTES.app.path} color="black" variant="secondary">
            <Trans>Go to the homepage</Trans>
          </Button>
        </Flex>
      }
      isFullPage
    />
  );
};

export default Error404;
