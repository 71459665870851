import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { CheckboxChangeEvent } from 'antd';

import { OptionType } from '@/api/common';
import { InstanceTaskEventSettingsType } from '@/api/organization';
import { Checkbox } from '@/components/Form';
import { Text } from '@/components/typography';

import styles from './CompanyNonDigitalTasksList.module.scss';

type Props = {
  taskOptions?: OptionType[];
  isDisabled?: boolean;
  isEditMode?: boolean;
  isHidden?: boolean;
  value?: InstanceTaskEventSettingsType | null;
  onChange?: (value: InstanceTaskEventSettingsType) => void;
};

const CompanyNonDigitalTasksList = ({
  taskOptions,
  isDisabled = false,
  isEditMode = false,
  isHidden = false,
  value,
  onChange,
}: Props) => {
  if (isHidden) {
    return isEditMode ? null : <Text variant="body1" italic>{t`none`}</Text>;
  }

  if (!isEditMode) {
    const selectedItems = value?.offlineTasksSpecific
      .map((id) => taskOptions?.find((option) => option.id === id)?.name)
      .concat(value?.offlineEventsAll ? t`Events` : []);

    if (!selectedItems?.length) {
      return <Text variant="body1" italic>{t`none`}</Text>;
    }

    return <Text variant="body1">{selectedItems?.join(', ')}</Text>;
  }

  const atLeastOneChecked = !!(value && value.offlineTasksSpecific.length > 0);
  const allChecked = taskOptions?.length === value?.offlineTasksSpecific.length;

  const handleAllTasksChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onChange?.({
        offlineTasksAll: true,
        offlineEventsAll: value?.offlineEventsAll ?? false,
        offlineTasksSpecific: taskOptions?.map((option) => option.id) ?? [],
      });
    } else {
      onChange?.({
        offlineTasksAll: false,
        offlineEventsAll: value?.offlineEventsAll ?? false,
        offlineTasksSpecific: [],
      });
    }
  };

  const handleAllEventsChange = (e: CheckboxChangeEvent) => {
    onChange?.({
      offlineTasksAll: value?.offlineTasksAll ?? false,
      offlineEventsAll: e.target.checked,
      offlineTasksSpecific: value?.offlineTasksSpecific ?? [],
    });
  };

  const handleTasksChange = (values: string[]) => {
    const isAllSelected = values.length === taskOptions?.length;
    onChange?.({
      offlineTasksAll: isAllSelected,
      offlineEventsAll: value?.offlineEventsAll ?? false,
      offlineTasksSpecific: values,
    });
  };

  return (
    <div className={styles.list}>
      <Checkbox
        indeterminate={atLeastOneChecked && !allChecked}
        checked={allChecked}
        isDisabled={isDisabled}
        onChange={handleAllTasksChange}
      >
        <Trans>Tasks</Trans>
      </Checkbox>
      <Checkbox.Group
        className={styles.checkboxGroup}
        options={taskOptions?.map((taskType) => ({
          label: taskType.name,
          value: taskType.id,
        }))}
        vertical
        isDisabled={isDisabled}
        value={value?.offlineTasksSpecific}
        onChange={handleTasksChange}
      />
      <Checkbox
        checked={value?.offlineEventsAll}
        isDisabled={isDisabled}
        onChange={handleAllEventsChange}
      >
        <Trans>Events</Trans>
      </Checkbox>
    </div>
  );
};

export default CompanyNonDigitalTasksList;
