import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useAudienceById, useAudienceMembership } from '@/api/audience';
import InstantSearch, { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import { ReturnToLink } from '@/components/ReturnToLink';
import TableActions from '@/components/TableActions/TableActions';
import Error404 from '@/components/errors/Error404';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import SolAPIError from '@/error/SolAPIError';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

import AudienceMembershipListTable from './AudienceMembershipListTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'name',
  sortOrder: InstantSearchSortOrder.ASC,
};

const AudienceDetail = () => {
  const [queryState, setQueryState] = useState<QueryState>();
  const { audience: audienceId } = useParams();
  const {
    audience,
    isLoading: isAudienceLoading,
    error: audienceError,
  } = useAudienceById(audienceId);
  const {
    audienceMembership,
    totalResults,
    isLoading: isMembershipLoading,
    error: membershipError,
  } = useAudienceMembership(audienceId, queryState);

  return (
    <Page title={t`Audience`} pageName={audience?.name}>
      <ReturnToLink route={ROUTES.audiences.path} routeName={<Trans>Audiences</Trans>} />
      <InstantSearch
        prefix="a"
        defaultQueryState={DEFAULT_QUERY_STATE}
        onQueryStateChange={setQueryState}
      >
        {audienceError instanceof SolAPIError && audienceError.is404() ? (
          <Error404 message={<Trans>The audience you're looking for does not exist</Trans>} />
        ) : (
          <Space direction="vertical" size="large">
            <TableActions />
            <Panel
              size="L"
              title={
                audience ? (
                  <Trans>
                    {audience?.name} ({numberFormat(audience?.metrics.size)} companies)
                  </Trans>
                ) : (
                  <Trans>Audience Details</Trans>
                )
              }
            >
              <AudienceMembershipListTable
                data={audienceMembership}
                totalResults={totalResults}
                isLoading={isAudienceLoading || isMembershipLoading}
                error={membershipError}
              />
            </Panel>
          </Space>
        )}
      </InstantSearch>
    </Page>
  );
};

export default AudienceDetail;
