import {
  Active,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMemo, useState } from 'react';

import { OptionType } from '@/api/common';
import useControlled from '@/hooks/useControlled';

import { List, ListContext, ListProps } from '../List';
import SortableListItem from './SortableListItem';
import SortableListOverlay from './SortableListOverlay';

type Props<T> = ListProps<T> & {
  defaultOptions?: T[];
  onOptionsChange?: (options: T[] | undefined) => void;
};

const SortableList = <T extends OptionType>({
  defaultOptions,
  defaultValue: defaultValue,
  options: optionsFromProps,
  value: selectedKeysFromProps,
  renderItem,
  selectionMode = 'none',
  isLoading = false,
  isLoadingMore = false,
  error,
  onOptionsChange: onOptionsChangeFromProps,
  onChange: onSelectedKeysChangeFromProps,
  ...rest
}: Props<T>) => {
  const [selectedKeys, onSelectedKeysChange] = useControlled(
    selectedKeysFromProps,
    defaultValue,
    onSelectedKeysChangeFromProps,
  );
  const [options, onOptionsChange] = useControlled(
    optionsFromProps,
    defaultOptions,
    onOptionsChangeFromProps,
  );
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => options?.find((item) => item.id === active?.id),
    [active, options],
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActive(active);
  };

  const sortSelectedKeys = (keys: string[], sortedArray: T[]) =>
    keys.sort(
      (a, b) =>
        sortedArray!.findIndex((item) => item.id === a) -
        sortedArray!.findIndex((item) => item.id === b),
    );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (options && over && active.id !== over?.id) {
      const activeIndex = options.findIndex(({ id }) => id === active.id);
      const overIndex = options.findIndex(({ id }) => id === over.id);

      const newOptions = arrayMove(options, activeIndex, overIndex);
      onOptionsChange(newOptions);
      onSelectedKeysChange(sortSelectedKeys(selectedKeys ?? [], newOptions));
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const handleSelectedKeysChange = (keys: string[] | undefined) => {
    onSelectedKeysChange(sortSelectedKeys(keys ?? [], options ?? []));
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <ListContext.Provider
        value={{ value: selectedKeys, onChange: onSelectedKeysChange, selectionMode }}
      >
        <SortableContext strategy={verticalListSortingStrategy} items={options!}>
          <List
            value={selectedKeys}
            options={options}
            renderItem={renderItem}
            selectionMode={selectionMode}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            error={error}
            onChange={handleSelectedKeysChange}
            {...rest}
          />
        </SortableContext>
        <SortableListOverlay>{activeItem ? renderItem(activeItem) : null}</SortableListOverlay>
      </ListContext.Provider>
    </DndContext>
  );
};

SortableList.Item = SortableListItem;

export default SortableList;
